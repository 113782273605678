.footer{
    position: fixed;
    z-index: 1999;
    left: 0px;
    bottom: 20px;
    width:55vw;

    margin: 0;
    text-align: center;

    &--tv{

        span{
            position: fixed;
            bottom: 10px;
            left: 10px;
            color: #222;
        }
    }

    @include breakpoint( $breakpoint_l ) {
        position: relative;
        margin: 15vh auto;
        width: 100%;
        // display: none;
    }

    img{
        margin: 200px 0 20px 0;
    }

    p{
        margin: 0;
        padding: 0 50px;
        color: #222;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: .7px;
        transition: all .2s ease-in-out;

        @include breakpoint( $breakpoint_m ) {
            padding: 0 10px;
            font-size: 12px;
            line-height: 19px;
        }
    }

    &__social{
        margin: 10px 0 0 0;
    }

    &__btn{
        margin: 10px;
        padding: 0 5px;
        border: 3px solid #fff;
        border: none;
        background: none;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // box-shadow: 0 0 5px rgba(0,0,0,.2);
        // text-shadow: 0 0 3px rgba(0,0,0,.8);
        transition: all .2s ease-out;

        svg{
            width: 24px;
            height: 32px;
            fill: #fff;
            stroke: #fff;
            pointer-events: none;
            transition: all .2s ease-out;
    
            @include breakpoint( $breakpoint_m ) {
                width: 16px;
                height: 24px;
            }
        }

        &:hover{
            transform: scale(1.3);
        }

        &--shuffle{
            position: absolute;
            bottom: 0;
            right: 0;
        }
        
    }
}

.left{
    .footer{
        display: block;
        @include breakpoint( $breakpoint_l ) {
            display: none;
        }
    }
}
.right{
    .footer{
        display: none;

        @include breakpoint( $breakpoint_l ) {
            position: relative;
            display: block;
            width: 100%;
            margin: 10vh 0 0 0;
        }
    }
}