body,
body *{
    cursor: none !important;
}

.cursor{

    position: fixed;
    z-index: 10001;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    pointer-events: none;

    margin: 12px;
    border: 0px solid transparent;
    border-width: 0px;
    transform-origin: center center;

    transition: 
        background-color .2s ease-in-out, 
        border-color .2s ease-in-out, 
        border-radius .1s ease-in;
        // margin .1s ease-in,
        // border-width .2s ease-in;

    &.hide{
        display: none !important;
    }

    &.active{
        // border-radius: 0;
        width: 64px;
        height: 64px;
        // background: none !important;
        margin: 0px;
        border-width: 0px;
    }

    &.blend{
        // background: none !important;
        // border-width: 10px;
        // transform: translate(-32px, -32px);
        width: 100px;
        height: 100px;
        mix-blend-mode:difference;
    }

    &.menu{
        background: none !important;
        border-width: 6px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: space-between;
        flex-direction: column;
        flex-wrap: nowrap;

        &:before{
            // display: block;
            content: '•';
            text-align: center;
            vertical-align: middle;
            font-size: 100px;
            font-weight: 700;
            // line-height: 2px;

            animation-name: rotate;
            animation-duration: .9s;
            animation-iteration-count: 1;
            animation-direction: normal;
        }
    }

    &.circled{
        background: none !important;
        border-width: 6px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: space-between;
        flex-direction: column;
        flex-wrap: nowrap;

        &:before{
            // display: block;
            content: '+';
            text-align: center;
            vertical-align: middle;
            font-size: 30px;
            font-weight: 700;
            // line-height: 2px;

            animation-name: rotate;
            animation-duration: .3s;
            animation-iteration-count: 1;
            animation-direction: normal;
        }
    }

    svg{
        width: 100px;
        height: 100px;
    }
}