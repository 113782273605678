@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Comforta:wght@100;300;400;700&display=swap');

.main{
    font-family: 'Urbanist', sans-serif;
}

button{
    font-family: 'Urbanist', sans-serif;
}

.capitalize{
    text-transform: capitalize;
}