html, body{
    margin: 0;
    padding: 0;
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: sans-serif;
    font-size: 14px;

    width: 100vw;
    height: 100vh;

    overflow-x: hidden;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    transition: all .7s ease-in-out;

    @include breakpoint( $breakpoint_l ) {
        flex-direction: column;
        min-height: 100vh;
    }

    .left,
    .right{
        transition: width .2s ease-in-out;
    }
}

[data-content="images"]{
    .left{
        position: relative;
        width: 55vw;
        height: 100vh;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: 45vh;
        }
    }
    .right{
        position: relative;
        width: 45vw;
        height: 100vh;
        overflow: hidden;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: auto
        }
    }
}
[data-content="videos"]{
    .left{
        position: relative;
        // width: calc( );
        width: calc( 100vw -  ((100vh / 5) * 2.5) );
        height: 100%;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: 45vh;
        }

        .info{
            // text-align: left;

            .logo{
                // opacity: 0;
                // margin: 5vh 0 0 5vh;
            }
            .slogan{
                // margin: 0 0 0 5vh;
            }
        }
        .words{
            &__wrapper{
                // justify-content: flex-start;
            }
        }
        .footer{
            // width: calc( 100vw - 450px);
            width: calc( 100vw -  ((100vh / 5) * 3) );
        }
    }
    .right{
        position: relative;
        width: calc((100vh / 5) * 3);
        // width: 450px;
        height: 100vh;
        overflow: hidden;
        
        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: auto;
        }
    }
}
[data-content="listing"]{
    .wrapper{
        justify-content: flex-start;
    }
    .left{
        position: relative;
        width: 75vw;
        height: 100vh;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: 35vh;
        }

        .footer{
            width: 75vw;
        }
    }
    .right{
        position: relative;
        width: 25vw;
        height: 100vh;
        overflow: hidden;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: auto
        }
    }
}
[data-content="cities"]{
    .wrapper{
        justify-content: flex-start;
    }
    .left{
        position: relative;
        width: 0vw;
        height: 100vh;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: 35vh;
        }

        .info{
            display: none;
        }

        .footer{
            width: 0vw;
            overflow: hidden;
        }
    }
    .right{
        position: relative;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: auto
        }
    }
}

[data-content="tv"]{
    .left{
        position: relative;
        width: 45vw;
        height: 100vh;

        .footer{
            width: 45vw;
        }
    }
    .right{
        position: relative;
        width: 55vw;
        height: 100vh;
        overflow: hidden;

        .persons{
            width: 100%;

            &__item{
                width: 100vw !important;
            }
        }

        @include breakpoint( $breakpoint_l ) {
            width: 100vw;
            height: auto
        }
    }

    .cursor{
        display: none !important;
    }
}