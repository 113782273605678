.info{
    margin: 0 auto 0 auto;
    text-align: center;
    // max-width: 350px;

    .logo{
        margin: 5vh auto 0 auto;
        transition: all .3s ease-in-out;
        width: 500px;

        @include breakpoint( $breakpoint_xl ) {
            width: 400px;
        }

        @include breakpoint( $breakpoint_l ) {
            width: 500px;
        }

        @include breakpoint( $breakpoint_m ) {
            width: 300px;
        }
    }

    .slogan{
        display: block;
        width: 100%;

        margin: 6px auto 0 auto;

        font-size: 13px;
        line-height: 21px;
        font-weight: 900;
        letter-spacing: 4.5px;
        text-transform: uppercase;

        transition: all .3s ease-in-out .1s;

        @include breakpoint( $breakpoint_l ) {
            margin: 3px auto;
            font-size: 10px;
            line-height: 17px;
            font-weight: 700;
        }
        
    }
}