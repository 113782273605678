.persons{

    position: relative;
    height: 100vh;
    width: 45vw;
    overflow-y: auto;
    transition: all .6s ease-in-out;

    @include breakpoint( $breakpoint_l ) {
        height: auto;
        position: fixed;
        width: 100%;
        top: 55vh;
        height: 45vh;
    }

    @include breakpoint( $breakpoint_m ) {
        top: 45vh;
        height: 55vh;
    }

    &__wrapper{
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: flex-end;
        flex: auto;
    }

    &__item{
        box-sizing: border-box;
        // opacity: .2;
        cursor: pointer;
        transition: all .25s ease-in-out;
        overflow: hidden;
        flex-grow: 1;

        &:hover{
            // cursor: url("../../../public/icon_set.png") 16 16, pointer;
            opacity: .85;
        }

        width: 20%;
/*
        @include breakpoint( $breakpoint_l ) {
            width: 25%;
        }
        @include breakpoint( $breakpoint_m ) {
            width: 33.33%;
        }
        @include breakpoint( $breakpoint_s ) {
            width: 50%;
        }
        */
        

        &--active{
            opacity: 1 !important;

            width: 80%;
            /*
            
            @include breakpoint( $breakpoint_l ) {
                width: 75%;
            }
            @include breakpoint( $breakpoint_m ) {
                width: 66.66%;
            }
            @include breakpoint( $breakpoint_s ) {
                width: 50%;
            }
            */

        }

        img{
            display: block;
            pointer-events: none;
            width: 100%;
            transition: all .15s ease-out;
        }
    }
}