.reels{
    position: absolute;
    right: 0;
    top: 0;

    width: 100%;
    height: 100vh;

    @include breakpoint( $breakpoint_l ) {
        position: relative;
        height: auto;
    }

    &.ended{
        .reels__progress{   
            bottom: -10px;
        }
    }

    &:hover{
        .reels__info{
            left: 0;
        }
    }


    &__loader{
        position: absolute;
        z-index: 90;
        top: 0;
        left: 0;
        margin: 10px;

        display: none;
        &.active{
            display: block;
        }

        svg{
            width: 24px;
            height: 32px;
            fill: #fff;
            stroke: #fff;
            pointer-events: none;
            transition: all .2s ease-out;

            animation-name: rotate;
            animation-duration: .5s;
            animation-iteration-count: infinite;
            animation-direction: normal;
            animation-timing-function: linear;

            @include breakpoint( $breakpoint_m ) {
                width: 16px;
                height: 24px;
            }
        }
    }

    &__shuffle,
    &__mute{
        margin: 10px;
        border: 3px solid #fff;
        border: none;
        background: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        text-shadow: 0 0 3px rgba(0,0,0,.8);

        svg{
            width: 24px;
            height: 32px;
            fill: #fff;
            stroke: #fff;
            pointer-events: none;
            transition: all .2s ease-out;

            @include breakpoint( $breakpoint_m ) {
                width: 16px;
                height: 24px;
            }
        }
    }

    &__shuffle{
        position: absolute;
        z-index: 997;
        bottom: 0px;
        right: 0px;
    }

    &__mute{
        position: absolute;
        z-index: 997;
        top: 0px;
        right: 0px;
    }

    &__item{
        width: 100%;

        video{
            height: 100vh;
            width: 100%;
            object-fit: cover;
            object-position: center center;

            @include breakpoint( $breakpoint_m ) {
                height: auto;
                width: 100vw;
            }
        }
    }

    &__info{
        position: absolute;
        z-index: 1001;
        bottom: 0;
        left: calc( -50% - 40px);
        max-width: 50%;
        margin: 12px 10px;
        padding: 10px 15px;
        transition: all .15s ease-out;

        &:empty{
            padding: 0;
            display: none;
        }

        h2{
            display: block;
            margin: 0 0 0 0;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: .5;
            font-weight: 700;
    
            @include breakpoint( $breakpoint_l ) {
                font-size: 15px;
                line-height: 15px;
            }
        }
        h3{
            display: block;
            margin: 4px 0 0 0;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: .5;
            font-weight: 400;
        }
        svg{
            margin: 0 5px -4px -2px;
            width: 16px;
            height: 16px;
            pointer-events: none;
        }
    }

    &__progress{
        position: absolute;
        z-index: 90;
        bottom: 0;
        left: 0;
        height: 3px;
        transition: width .3s linear;

        @include breakpoint( $breakpoint_l ) {
            top: 0;
            bottom: auto;
        }
    }

    .fx{
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
    }
}