.cities{

    height: 100vh;
    display: flex;
    align-items: center;

    @include breakpoint( $breakpoint_l ) {
        height: auto;
        position: fixed;
        width: 100%;
        top: 45vh;
        height: 55vh;
    }

    &__wrapper{
        max-width: 50%;
        margin: 0 auto;
    }

    h2{
        text-align: center;
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;
        letter-spacing: 0px;
    }

    &__links{
        display: flex;
        justify-content: center;

        padding: 10px 0;
        width: calc( 100% - 0px );
        // height: calc(100vh - 20px);
        overflow-y: auto;
        transition: all .6s ease-in-out;
        // background: transparent !important;

        @include breakpoint( $breakpoint_l ) {
            padding: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            text-align: center;
        }
    }

    &__link{
        margin: 10px 40px;
        padding: 10px 10px;
        border-bottom: 6px solid #fff;
        background: none;
        text-decoration: none;
        transition: all .1s ease-out;

        display: inline-block;
        font-size: 50px;
        line-height: 50px;
        font-weight: 700;
        letter-spacing: 0px;

        &:not(.active):hover{
            // letter-spacing: 2px;
            opacity: .5;
        }

        &.active{
            opacity: .35;
            // border: none !important;
        }
    }
    
}