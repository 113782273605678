.words{

    margin: 28vh 0;
    width: 100%;
    overflow: hidden;

    @include breakpointH( 900px ) {
        margin: 20vh 0;
    }
    @include breakpointH( 600px ) {
        margin: 10vh 0;
    }

    @include breakpoint( $breakpoint_l ) {
        margin: 8vh 0;
    }

    @include breakpoint( $breakpoint_m ) {
        margin: 8vh 0;
    }

    &__wrapper{
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .word{
        margin: 0 25px;
        padding: 5px 0px 18px 0px;
        transition: all .2s ease-in-out;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: flex-start;
        text-align: center;
        transform-origin: center center;

        // height: 50vh;

        // container-type: inline-size;

        &:first-of-type{
            // padding-left: 50vw;
        }
        &:last-of-type{
            // padding-right: 50vw;
        }

        &:not(.word--active){
            .word__original{
                //
            }
        }

        &--active{

            .word__original{
                opacity: 1;
                // filter: grayscale(0);
                padding-bottom: 5px;
                border-bottom: 13px solid transparent;

                transition: all .5s ease-in-out .2s;

                @include breakpoint( $breakpoint_l ) {
                    border-bottom-width: 5px;
                }
            }
        }

        &__original{
            position: relative;
            padding: 0 0px;
            font-size: 100px;
            line-height: 100px;
            font-weight: 700;
            // text-transform: uppercase;
            white-space: nowrap;
            text-align: center;
            // overflow: hidden;
            transform-origin: center center;
            overflow: hidden;
            opacity: .05;
            cursor: pointer;
            // filter: grayscale(1);

            &:not(.fx){
                width: 0;
            }

            &.fx{
                animation-name: slide-in;
                animation-duration: .45s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-timing-function: cubic-bezier(.17,.67,.6,1);
            }

            transition: opacity .5s ease-in-out .2s;

            @include breakpoint( $breakpoint_l ) {
                font-size: 65px;
                line-height: 65px;
                padding: 0 0px;
            }

            @include breakpoint( $breakpoint_m ) {
                font-size: 45px;
                line-height: 45px;
                padding: 0 0px;
            }
        }

        &__footer{
            display: flex;
            justify-content: space-between;
            // overflow: hidden;
            transition: all .5s ease-in-out;

            &.fx{
                .word__translation{
                    opacity: 1;
                    animation-name: fade-in;
                    animation-duration: 4s;
                    animation-iteration-count: 1;
                    animation-direction: normal;
                    animation-timing-function: ease-out;
                }
            }
        }

        &__translation{
            opacity: 0;
            display: block;
            margin: 20px 0 0 0;
            padding: 0 10px;
            // opacity: 0;
            font-size: 28px;
            line-height: 28px;
            // text-transform: uppercase;
            white-space: nowrap;
            transition: none .25s cubic-bezier(.17,.67,.6,1);
            // transform: translateY(-40px);

            @include breakpoint( $breakpoint_l ) {
                font-size: 24px;
                line-height: 24px;
            }

            @include breakpoint( $breakpoint_m ) {
                font-size: 20px;
                line-height: 20px;
            }

            &:nth-of-type(1){
                padding: 0 20px 0 0;
                font-weight: 700;
            }
            &:nth-of-type(2){
                padding: 0 0 0 20px;
                font-weight: 400;
            }
        }
    }
}