.person{
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include breakpoint( $breakpoint_l ) {
        height: 75vh;
    }

    cursor: pointer;

    &:hover{
        // cursor: url("../../../public/icon_minus.png") 16 16, pointer;
    }

    &.fx{

        .person__image{
            /*
            animation-name: zoom-in;
            animation-duration: 12.5s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-timing-function: ease-out;

            @include breakpoint( $breakpoint_m ) {
                transform: translate(0) !important;
            }
            */
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all .1s ease-in;
        // transform: scale(1.1);
    }

    &__progress{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;

        @include breakpoint( $breakpoint_l ) {
            top: 0;
            bottom: auto;
        }


        &.fx{
            animation-name: progress;
            animation-duration: 7s;
            animation-iteration-count: 1;
            animation-direction: normal;
        }
    }
}

.person-old{
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    transition: all .2s ease-in;

    display: none;
    &--active{
        display: block;
    }

    animation-name: fade-out;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;

    @include breakpoint( $breakpoint_l ) {
        height: 75vh;
    }
}