.actions{
    position: fixed;
    z-index: 999;
    bottom: 0px;
    right: 0px;

    button{
        margin: 10px;
        border: 3px solid #fff;
        border: none;
        background: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        text-shadow: 0 0 3px rgba(0,0,0,.8);

        &:nth-of-type(1){
            transition: all .2s ease-out .2s;
        }
        &:nth-of-type(2){
            position: absolute;
            left: calc( -45vw + 60px );
            bottom: 0;
            transition: all .2s ease-out .3s;

            @include breakpoint( $breakpoint_m ) {
                left: calc( -100vw + 50px );
            }
        }
        &:nth-of-type(3){
            transition: all .2s ease-out .4s;
        }
        
    }

    svg{
        width: 24px;
        height: 32px;
        fill: #fff;
        stroke: #fff;
        pointer-events: none;
        transition: all .2s ease-out;

        @include breakpoint( $breakpoint_m ) {
            width: 16px;
            height: 24px;
        }
    }

    .fx{
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
    }
}