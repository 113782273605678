.listing{
    transition: all .6s ease-in-out;

    @include breakpoint( $breakpoint_l ) {
        height: auto;
        position: fixed;
        width: 100%;
        top: 45vh;
        height: 55vh;
    }

    &__wrapper{
        padding: 10px 20px 10px 40px;
        width: calc( 100% - 60px );
        height: calc(100vh - 20px);
        overflow-y: auto;
        transition: all .6s ease-in-out;
        // background: transparent !important;

        @include breakpoint( $breakpoint_l ) {
            padding: 10px;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            text-align: center;
        }
    }

    h2{
        margin: 35px 0 0 0;
        font-size: 50px;
        line-height: 58px;
    }

    h3{
        margin: 25px 0;
        font-size: 30px;
        line-height: 38px;
    }

    p{
        font-size: 19px;
        line-height: 28px;
        word-break: break-word;
    }

    &__item{
        display: block;
        margin: 5px 0;
        padding: 10px 50px 10px 0;

        @include breakpoint( $breakpoint_l ) {
            // display: inline-block;
            padding: 15px;
        }
    }

    span{
        font-size: 28px;
        line-height: 28px;
        font-weight: 700;
        cursor: pointer;
        border-bottom: 5px solid transparent;
        pointer-events: none;

        @include breakpoint( $breakpoint_l ) {
            font-size: 24px;
            line-height: 24px;
        }

        @include breakpoint( $breakpoint_m ) {
            font-size: 20px;
            line-height: 20px;
        }

        &:hover{
            font-style: italic;
        }
    }

    &__btn{
        margin: 10px;
        border: 3px solid #fff;
        border: none;
        background: none;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        text-shadow: 0 0 3px rgba(0,0,0,.8);
        transition: all .2s ease-out .3s;

        svg{
            width: 24px;
            height: 32px;
            fill: #fff;
            stroke: #fff;
            pointer-events: none;
            transition: all .2s ease-out;
    
            @include breakpoint( $breakpoint_m ) {
                width: 16px;
                height: 24px;
            }
        }

        &--shuffle{
            position: absolute;
            bottom: 0;
            right: 0;
        }
        
    }

    .fx{
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-direction: normal;
    }
    
}