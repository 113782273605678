.translations{

    margin: 30px auto 0 auto;
    overflow: hidden;

    &__wrapper{
        display: flex;
        justify-content: center;

        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }
}