@keyframes progress {
    from {width: 0%;}
    to {width: 100%;}
}

@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: scale(1.01,1.01);
    }
    100% {
        opacity: 0;
        transform: scale(1.01,1.01);
    }
}

@keyframes zoom-in {
    0% {transform: scale(.1);}
    50% {transform: scale(1);}
    100% {transform: scale(1);}
}

@keyframes slide-in {
    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 1;
    }
}

@keyframes slide-in-vert {
    0% {
        height: 0%;
        opacity: 0;
    }
    10% {
        height: 100%;
        opacity: 1;
    }
    100% {
        height: 100%;
        opacity: 1;
    }
}