.content-switch{
    position: absolute;
    z-index: 2000;
    width: 0%;
    height: 100vh;
    left: 0px;
    top: 0px;
    // text-align: right;
    overflow: hidden;
    transition: all .2s ease-in-out;


    &--active{
        width: 100vw;
        height: 100vh;
        background: rgba(20,20,20,.99);

        @include breakpoint( $breakpoint_l ) {
            // width: 100%;
        }
    }

    &__container{
        padding: 50px 100px;
        height: calc( 100% - 100px );

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include breakpoint( $breakpoint_l ) {
            padding: 25px 50px;
        }

        .menu-item{
            margin: 3px 0;
            border: none;
            background: none;
            font-size: 50px;
            font-weight: 700;
            cursor: pointer;
            color: #fff;
            transition: all .1s ease-in-out;
            opacity: .7;

            @include breakpoint( $breakpoint_l ) {
                margin: 10px 0;
                font-size: 30px;
            }

            &.active{
                opacity: 1;
                text-decoration: underline;
            }
            &:hover{
                opacity: 1;
                transform: translateX(10px);
            }
        }
    }
}

.content-switch-toggler{
    position: fixed;
    z-index: 2001;
    left: 0;
    margin: 0px;
    border: none;
    background: none;
    cursor: pointer;

    @include breakpoint( $breakpoint_l ) {
        display: none;
    }

    svg{
        pointer-events: none;
        width: 64px;
        height: 64px;
        transition: all .25s ease-in-out;
    }

    &--active{

        svg{
            width: 32px;
            height: 32px;
            padding: 16px;
            transform: rotate(90deg);
        }
    }
}